import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import Select from "react-select";

// Custom Styles
const customStyles = {
  control: (props) => ({
    ...props,
    border: "1px solid #e4e6ef",
    borderRadius: 0,
  }),
  groupHeading: (base) => ({
    ...base,
    backgroundColor: "#f9fafb",
    padding: "8px 12px",
  }),
};

export default function AppSelect({
  name,
  value,
  onChange,
  multiple = false,
  options = [],
  loading = false,
  required = false,
  locale = false,
  grouped: _grouped = false,
  preferred = [],
  ...props
}) {
  const intl = useIntl();

  const _t = (id) => intl.formatMessage({ id });

  const grouped = _grouped || preferred?.length > 0;

  // Render Options
  const renderOptions = useCallback(() => {
    /** */

    // Action Options
    const actionOptions = [
      { code: "select_all", label: _t("T.SELECT_ALL"), value: "__select_all__" },
      { code: "select_reverse", label: _t("T.SELECT_REVERSE"), value: "__select_reverse__" },
    ].filter(({ code }) => (code === "select_reverse" ? value?.length > 0 : true));

    // Handle Preferred Options
    if (preferred?.length > 0) {
      /** */

      const preferredOptions = options.filter((opt) => preferred.includes(opt.value)); // Preferred Options
      const otherOptions = options.filter((opt) => !preferred.includes(opt.value)); // Other Options

      return [
        {
          label: _t("T.MOST_USED"),
          options: preferredOptions.map((opt) => ({
            label: locale ? _t(opt.label ?? "-") : opt.label,
            value: opt.value,
          })),
        },
        {
          label: _t("T.ALL"),
          options: otherOptions.map((opt) => ({
            label: locale ? _t(opt.label ?? "-") : opt.label,
            value: opt.value,
          })),
        },
        {
          label: _t("T.ACTIONS"),
          options: actionOptions,
        },
      ];
    }

    // Grouped Options
    else if (grouped) {
      return [
        ...options.map((group) => ({
          label: _t(group.label ?? "-"),
          options: group.options.map((opt) => ({
            label: _t(opt.label ?? "-"),
            value: opt.value,
          })),
        })),
        {
          label: _t("T.ACTIONS"),
          options: actionOptions,
        },
      ];
    }

    // Normal Options

    let _options = [];

    if (props?.placeholder && !multiple) _options.push({ label: props.placeholder, value: null }); // Add Placeholder If Provided (Single Mode)

    if (multiple) _options.push(...actionOptions); // Multiple Mode ? Append Action Options

    // Regular Options
    const regularOptions = options?.map(({ label, value }) => ({
      label: _t(label ?? "-"),
      value,
    }));

    return [..._options, ...regularOptions];

    /** */
  }, [options, multiple, value?.length, locale, intl, preferred?.length]);

  // Render Options
  const _options = useMemo(() => renderOptions(), [renderOptions]);

  // Flattened Options (Flatten In Case Of Grouped Mode)
  const __options = useMemo(() => (!grouped ? _options : _options.flatMap((group) => group.options)), [_options, grouped]);

  // Find Selected Value
  const findSelectedValue = useCallback(() => {
    return multiple ? __options.filter((option) => value?.includes(option.value)) : __options?.find((option) => option.value === value) ?? null;
  }, [__options, value, multiple]);

  // Handle Change
  const handleChange = useCallback(
    (selectedOptions) => {
      /** */

      // Multiple Mode
      if (!multiple) return onChange(selectedOptions?.value);

      // Handle array of selections
      if (Array.isArray(selectedOptions)) {
        /** */

        const lastSelected = selectedOptions[selectedOptions.length - 1];

        // Get all possible values excluding special options
        const getAllPossibleValues = () => {
          return __options.filter((opt) => opt.value !== "__select_all__" && opt.value !== "__select_reverse__" && opt.value !== null).map((opt) => opt.value);
        };

        // Handle Select All
        if (lastSelected?.value === "__select_all__") {
          const allValues = getAllPossibleValues();
          onChange(allValues);
          return;
        }

        // Handle Select Reverse
        if (lastSelected?.value === "__select_reverse__") {
          const allPossibleValues = getAllPossibleValues();
          const reversedSelection = allPossibleValues.filter((val) => !value?.includes(val));
          onChange(reversedSelection);
          return;
        }

        // Regular multi-select
        onChange(selectedOptions.map(({ value }) => value));

        /** */
      }
    },
    [multiple, onChange, __options, value, grouped]
  );

  const selectedValue = useMemo(() => findSelectedValue(), [findSelectedValue]);

  return (
    <Select
      styles={customStyles}
      isDisabled={props.disabled}
      instanceId={name}
      options={_options}
      isOptionDisabled={required ? ({ value }) => !Boolean(value) : undefined}
      onChange={handleChange}
      value={selectedValue}
      isLoading={loading}
      isMulti={multiple}
      {...props}
    />
  );
}
