import AppButton from "@components/AppButton";
import AppIcon from "@components/AppIcon";
import Locale from "@components/core/Locale";
import { AppContext } from "@context/AppContext";
import { Dialog } from "@headlessui/react";
import {
  format,
  getDate,
  getDay,
  getDaysInMonth,
  getMonth,
  getYear,
  isToday as isDateToday,
  setDefaultOptions,
  setHours,
  setMinutes,
  startOfMonth,
} from "date-fns";
import { enNZ, enUS, tr } from "date-fns/locale";
import { forwardRef, useContext, useMemo, useState } from "react";

// Map of supported locales
const LOCALES = {
  tr: tr,
  "tr-TR": tr,
  "en-US": enUS,
  "en-NZ": enNZ,
};

const formatDisplayDate = (formatString, date, showTime = false) => {
  if (!date) return "";
  let validFormat = formatString?.split(" ")[0]?.replace("YYYY", "yyyy").replace("DD", "dd");
  return format(date, showTime ? `${validFormat} HH:mm:ss` : validFormat);
};

const DateTimePickerInput = forwardRef(({ value, onClick, time, format, ...props }, ref) => (
  <button
    onClick={onClick}
    ref={ref}
    type="button"
    disabled={props?.disabled}
    className={`form flex items-center justify-between ${props?.disabled ? "bg-gray-50 cursor-not-allowed" : "cursor-pointer"}`}
  >
    <span className={`block text-base ${!value ? "text-gray-500" : "text-gray-700"}`}>
      {Boolean(value) ? formatDisplayDate(format, new Date(value), time) : <Locale code="L.SELECT" />}
    </span>
    <div className="flex items-center gap-2 text-gray-400">
      {time && <AppIcon code="fa-clock" className="h-5 w-5" />}
      <AppIcon code="fa-calendar" className="h-5 w-5" />
    </div>
  </button>
));

DateTimePickerInput.displayName = "DateTimePickerInput";

function AppDateTimePicker({ value, onChange, time = false, data = {}, ...props }) {
  const { PM, CM } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(value ? new Date(value) : new Date());
  const [selectedDate, setSelectedDate] = useState(value ? new Date(value) : null);
  const [selectedTime, setSelectedTime] = useState(value ? format(new Date(value), "HH:mm") : "09:00");
  const [view, setView] = useState("date");

  // Calendar Locale
  const calendar = PM?.profile?.default?.calendar ?? CM?.profile?.default?.calendar ?? "en-US";
  const locale = LOCALES[calendar ?? "en-US"];

  // Set Date-FNS Default Locale
  setDefaultOptions({ locale });

  // Memoize time slots to prevent recalculation
  const timeSlots = useMemo(() => {
    const slots = [];
    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 30) {
        const time = new Date();
        time.setHours(hour, minute, 0);
        slots.push({
          value: format(time, "HH:mm"),
          label: format(time, "hh:mm aa"),
        });
      }
    }
    return slots;
  }, []);

  // Memoize weekDays to prevent recalculation on every render
  const weekDays = useMemo(() => {
    const weekDays = Array.from({ length: 7 }, (_, i) => {
      const startDay = calendar === "en-US" ? 0 : 1;
      const date = new Date(2024, 0, startDay + i);
      return format(date, "EEE", { locale });
    });
    return weekDays;
  }, [locale, calendar]);

  // Memoize days grid calculation
  const daysGrid = useMemo(() => {
    const daysInMonth = getDaysInMonth(currentDate);
    const firstDayOfMonth = getDay(startOfMonth(currentDate));
    const adjustedFirstDay = calendar === "en-US" ? firstDayOfMonth : firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1;
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const blanks = Array.from({ length: adjustedFirstDay }, (_, i) => null);
    return [...blanks, ...days];
  }, [currentDate, calendar]);

  // Memoize the data mapping for current month
  const currentMonthData = useMemo(() => {
    const monthData = {};
    daysGrid.forEach((day) => {
      if (day) {
        const date = new Date(Date.UTC(getYear(currentDate), getMonth(currentDate), day));
        const currentDayDate = date.toISOString().split("T")[0];
        if (data?.[currentDayDate]) {
          monthData[day] = data[currentDayDate];
        }
      }
    });
    return monthData;
  }, [data, currentDate, daysGrid]);

  const handleDateSelect = (day) => {
    const newDate = new Date(
      getYear(currentDate),
      getMonth(currentDate),
      day,
      time ? parseInt(selectedTime.split(":")[0]) : 0,
      time ? parseInt(selectedTime.split(":")[1]) : 0
    );
    setSelectedDate(newDate);
    if (!time) {
      onChange(newDate.toISOString());
      setIsOpen(false);
    } else {
      setView("time");
    }
  };

  const handleTimeSelect = () => {
    if (!selectedDate) return;
    const [hours, minutes] = selectedTime.split(":").map(Number);
    const newDate = setMinutes(setHours(selectedDate, hours), minutes);
    onChange(newDate.toISOString());
    setIsOpen(false);
  };

  const handlePrevMonth = () => setCurrentDate(new Date(getYear(currentDate), getMonth(currentDate) - 1, 1));
  const handleNextMonth = () => setCurrentDate(new Date(getYear(currentDate), getMonth(currentDate) + 1, 1));

  const isToday = (day) => {
    if (!day) return false;
    const date = new Date(getYear(currentDate), getMonth(currentDate), day);
    return isDateToday(date);
  };

  const isSelected = (day) => {
    if (!selectedDate || !day) return false;
    return day === getDate(selectedDate) && getMonth(currentDate) === getMonth(selectedDate) && getYear(currentDate) === getYear(selectedDate);
  };

  return (
    <div className="relative" {...props}>
      <DateTimePickerInput format={PM.profile?.default?.dateFormat ?? "yyyy-MM-dd"} value={value} onClick={() => setIsOpen(true)} time={time} {...props} />
      <Dialog as="div" className="relative z-50" open={isOpen} onClose={() => setIsOpen(false)}>
        <div className="fixed inset-0 bg-black/10" aria-hidden="true" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            {/* Panel */}
            <Dialog.Panel className="w-full max-w-[450px] overflow-hidden bg-white text-left align-middle border rounded-md">
              {/* Header */}
              <div className="flex items-center justify-between p-5 border-b">
                <Dialog.Title as="div" className="flex items-center gap-2">
                  {view === "time" && (
                    <AppIcon onClick={() => setView("date")} code="fa-chevron-left" className="h-7 w-7 text-gray-400 hover:text-gray-500 cursor-pointer" />
                  )}
                  <span className="text-lg font-semibold">{view === "date" ? format(currentDate, "MMMM yyyy", { locale }) : "Select Time"}</span>
                </Dialog.Title>
                <button onClick={() => setIsOpen(false)}>
                  <AppIcon code="fa-x" className="w-7 h-7 text-gray-400 hover:text-gray-500 transition-colors" />
                </button>
              </div>

              {view === "date" && (
                <>
                  {/* Calendar */}
                  <div className="">
                    {/* Month Header */}
                    <div className="flex items-center justify-between border-b p-3">
                      <button onClick={handlePrevMonth} className="p-4 text-gray-500 hover:text-gray-900 hover:bg-gray-100">
                        <AppIcon code="fa-chevron-left" className="h-7 w-7" />
                      </button>
                      <span className="text-lg font-medium">{format(currentDate, "MMMM yyyy", { locale })}</span>
                      <button onClick={handleNextMonth} className="p-4 text-gray-500 hover:text-gray-900 hover:bg-gray-100">
                        <AppIcon code="fa-chevron-right" className="h-7 w-7" />
                      </button>
                    </div>

                    {/* Week Days */}
                    <div className="grid grid-cols-7 divide-x border-b">
                      {/* Week Days Header */}
                      {weekDays.map((day) => (
                        <div key={day} className="p-3 text-lg text-gray-500 text-center font-medium">
                          {day}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* Days Grid */}
                  <div className="">
                    <div className="grid grid-cols-7">
                      {daysGrid.map((day, index) => {
                        const dayData = day ? currentMonthData[day] : null;
                        return (
                          <div
                            key={index}
                            onClick={() => day && handleDateSelect(day)}
                            disabled={!day}
                            className={`
                                relative p-6 text-lg font-semibold flex items-center justify-center border-b border-r
                                ${index % 7 === 6 ? "border-r-0" : "border-r"}
                                ${Math.floor(index / 7) === Math.floor((daysGrid.length - 1) / 7) ? "border-b-0" : "border-b"}
                                ${!day ? "bg-gray-50" : "text-gray-500 cursor-pointer"}
                                ${day && !isSelected(day) ? "hover:text-gray-900 hover:bg-gray-100" : ""}
                                ${isSelected(day) ? "bg-blue-500 text-white hover:bg-blue-600" : ""}
                                ${isToday(day) && !isSelected(day) ? "border-blue-500" : ""}
                              `}
                          >
                            {day}
                            {dayData && (
                              <span
                                className={`absolute bottom-0 left-0 right-0 text-right text-sm font-semibold px-2 py-0.5
                                ${isSelected(day) ? "bg-blue-400 text-white" : "text-orange-500"}`}
                              >
                                {dayData}
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </>
              )}

              {view === "time" && (
                <div className="flex flex-col">
                  <div className="p-4">
                    <select
                      value={selectedTime}
                      onChange={(e) => setSelectedTime(e.target.value)}
                      className="w-full text-base border border-gray-200 rounded-md p-2.5 focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    >
                      {timeSlots.map(({ value, label }) => (
                        <option key={value} value={value}>
                          {label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="p-4 border-t">
                    <AppButton mode="primary" className="w-full" onClick={handleTimeSelect} title="BTN.CONFIRM" />
                  </div>
                </div>
              )}
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default AppDateTimePicker;
