import { API_BASE_URL, CDN_BASE_URL } from ".";

const ADMIN = `${API_BASE_URL}/app/admin`;
const APP = `${API_BASE_URL}/app/app`;
const CMS = `${API_BASE_URL}/cms/admin`;
const ACADEMY = `${API_BASE_URL}/academy/app`;
const ANALYTICS = `${API_BASE_URL}/analytics/admin`;

const ENDPOINTS = {
  academy: {
    nugget: `${ACADEMY}/nuggets`,
    post: `${ACADEMY}/posts`,
    snack: `${ACADEMY}/snacks`,
  },
  action: `${ADMIN}/actions`,
  analytics: {
    field: `${ANALYTICS}/fields`,
    visitor: `${ANALYTICS}/visitors`,
  },
  app: {
    auth: `${APP}/auth`,
    media: `${APP}/medias`,
    public: `${APP}/public`,
  },
  appointment: `${ADMIN}/appointments`,
  booking: {
    driver: `${ADMIN}/booking/drivers`,
    hotel: `${ADMIN}/booking/hotels`,
    place: `${ADMIN}/booking/places`,
    reservation: `${ADMIN}/booking/reservations`,
    transfer: `${ADMIN}/booking/transfers`,
  },
  calendar: `${ADMIN}/calendar`,
  call: `${ADMIN}/calls`,
  callerID: `${ADMIN}/caller-ids`,
  campaign: `${ADMIN}/campaigns`,
  category: `${ADMIN}/categories`,
  cdn: CDN_BASE_URL,
  clinic: `${ADMIN}/clinics`,
  cms: {
    bfa: `${CMS}/bfas`,
    category: `${CMS}/categories`,
    faq: `${CMS}/faqs`,
    gallery: `${CMS}/galleries`,
    media: `${CMS}/medias`,
    pack: `${CMS}/packs`,
    post: `${CMS}/posts`,
    slider: `${CMS}/sliders`,
    testimonial: `${CMS}/testimonials`,
    treatment: `${CMS}/treatments`,
  },
  company: `${ADMIN}/companies`,
  contact: `${ADMIN}/contacts`,
  conversion: `${ADMIN}/conversions`,
  csv: `${ADMIN}/csv`,
  domain: `${ADMIN}/domains`,
  duty: `${ADMIN}/duties`,
  email: `${ADMIN}/emails`,
  facebook: {
    authorize: `${ADMIN}/facebook/authorize`,
    forms: `${ADMIN}/facebook/forms`,
    pages: `${ADMIN}/facebook/pages`,
  },
  field: `${ADMIN}/fields`,
  files: `${ADMIN}/files`,
  filler: `${ADMIN}/fillers`,
  filter: `${ADMIN}/filters`,
  finance: `${ADMIN}/finance`,
  form: `${ADMIN}/forms`,
  group: `${ADMIN}/groups`,
  hook: `${ADMIN}/hooks`,
  instruction: `${ADMIN}/instructions`,
  integration: {
    alotech: `${ADMIN}/integrations/alotech`,
    facebook: `${ADMIN}/integrations/facebook`,
    instagram: `${ADMIN}/integrations/instagram`,
    gateway: `${ADMIN}/integrations/gateways`,
    gmail: `${ADMIN}/integrations/gmail`,
    kobikom: `${ADMIN}/integrations/kobikom`,
    simai: `${ADMIN}/integrations/simai`,
    telnyx: `${ADMIN}/integrations/telnyx`,
    twilio: `${ADMIN}/integrations/twilio`,
    web: `${ADMIN}/integrations/web`,
    whatsapp: `${ADMIN}/integrations/whatsapp`,
    google: `${ADMIN}/integrations/google`,
  },
  invoice: `${ADMIN}/invoices`,
  key: `${ADMIN}/keys`,
  label: `${ADMIN}/labels`,
  lead: `${ADMIN}/leads`,
  level: `${ADMIN}/levels`,
  list: `${ADMIN}/lists`,
  log: {
    access: `${ADMIN}/logs/access`,
    call: `${ADMIN}/logs/call`,
    facebook: `${ADMIN}/logs/facebook`,
    sms: `${ADMIN}/logs/sms`,
    whatsapp: `${ADMIN}/logs/whatsapp`,
  },
  map: `${ADMIN}/maps`,
  match: `${ADMIN}/matches`,
  media: `${ADMIN}/medias`,
  mediaTag: `${ADMIN}/media-tags`,
  message: `${ADMIN}/messages`,
  messageTemplate: `${ADMIN}/message-templates`,
  metric: `${ADMIN}/metrics`,
  misc: `${ADMIN}/misc`,
  note: `${ADMIN}/notes`,
  notification: `${ADMIN}/notifications`,
  number: `${ADMIN}/numaras`,
  operation: `${ADMIN}/operations`,
  permisson: `${ADMIN}/permissions`,
  phone: `${ADMIN}/phones`,
  profiler: `${ADMIN}/profilers`,
  publication: {
    document: `${ADMIN}/publications/documents`,
    template: `${ADMIN}/publications/templates`,
    verify: `${ADMIN}/publications/verify`,
  },
  pitemplate: `${ADMIN}/pi-templates`,
  rate: `${ADMIN}/rates`,
  report: `${ADMIN}/reports`,
  resource: `${ADMIN}/resources`,
  role: `${ADMIN}/roles`,
  schedule: `${ADMIN}/schedules`,
  source: `${ADMIN}/sources`,
  staff: `${ADMIN}/staffs`,
  stats: {
    company: `${ADMIN}/stats/company`,
  },
  tag: `${ADMIN}/tags`,
  ptag: `${ADMIN}/payment-tags`,
  task: `${ADMIN}/tasks`,
  tax: `${ADMIN}/taxs`,
  timeline: `${ADMIN}/timelines`,
  timelineTemplate: `${ADMIN}/timeline-templates`,
  translation: `${ADMIN}/translations`,
  treatment: `${ADMIN}/treatments`,
  twilio: `${ADMIN}/twilio`,
  user: `${ADMIN}/users`,
  WPage: `${ADMIN}/web-pages`,
  whois: `${ADMIN}/whois`,
  event: `${ADMIN}/events`,
  asset: `${ADMIN}/assets`,
};

export default ENDPOINTS;
