import useGoogleMap from "@hooks/useGoogleMap";
import { createContext, useEffect, useState } from "react";

const AppLayoutContext = createContext();
const { Provider } = AppLayoutContext;

const AppLayoutProvider = ({ children }) => {
  /** */

  // Screen Width
  const [dimensions, setDimensions] = useState({ height: window.innerHeight, width: window.innerWidth });

  // Call
  const [call, setCall] = useState({ enabled: false, number: undefined });

  // Messenger
  const [messenger, setMessenger] = useState({ enabled: false });

  // Notification Drawer
  const [notification, setNotification] = useState({ enabled: false });

  // Elastic Searc
  const [elastic, setElastic] = useState({ enabled: false });

  // Alert Dialog
  const [dialog, setDialog] = useState({
    enabled: false,
    mode: "info",
    title: undefined,
    body: undefined,
    confirmButtonTitle: "BTN.OK",
    cancelButtonTitle: "BTN.CANCEL",
    onConfirm: () => {},
  });

  // Screen Width Update Handler
  const handleWindowSizeChange = () => setDimensions({ height: window.innerHeight, width: window.innerWidth });

  // Window Size Change Handler
  useEffect(() => {
    /** */

    // Handle Key Press
    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.code === "Space") setElastic({ enabled: true });
    };

    // Handle Window Resize
    const handleWindowSizeChange = () => setDimensions({ height: window.innerHeight, width: window.innerWidth });

    // Add Event Listeners
    window.addEventListener("keydown", handleKeyPress);
    window.addEventListener("resize", handleWindowSizeChange);

    // Cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
      window.removeEventListener("resize", handleWindowSizeChange);
    };

    /** */
  }, []);

  const components = {
    notification: {
      enabled: notification.enabled,
      show: () => setNotification(() => ({ enabled: true })),
      close: () => setNotification(() => ({ enabled: false })),
    },
    call: {
      enabled: call.enabled,
      number: call.number,
      show: (number) => setCall({ enabled: true, number }),
      close: () => setCall({ enabled: false, number: undefined }),
    },
    messenger: {
      enabled: messenger.enabled,
      conversationID: messenger.conversationID,
      leadID: messenger.leadID,
      show: (leadID, conversationID) => setMessenger({ enabled: true, leadID, conversationID }),
      close: () => setMessenger({ enabled: false }),
    },
    dialog: {
      enabled: dialog.enabled,
      mode: dialog.mode,
      title: dialog.title,
      body: dialog.body,
      confirmButtonTitle: dialog.confirmButtonTitle,
      cancelButtonTitle: dialog.cancelButtonTitle,
      onConfirm: dialog.onConfirm,
      show: ({ mode, title, body, onConfirm, confirmButtonTitle, cancelButtonTitle }) =>
        setDialog({ enabled: true, mode, title, body, confirmButtonTitle, cancelButtonTitle, onConfirm }),
      close: () => dialog.enabled && setDialog({ enabled: false }),
    },
    elastic: {
      enabled: elastic.enabled,
      show: () => setElastic({ enabled: true }),
      close: () => setElastic({ enabled: false }),
    },
  };

  return (
    <Provider
      value={{
        ...components,
        GoogleMap: useGoogleMap(),
        dimensions,
        isMobile: dimensions.width < 768,
      }}
    >
      {children}
    </Provider>
  );
};
export { AppLayoutContext, AppLayoutProvider };
